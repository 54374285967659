/*==========End Responesive==========*/

@media screen and (min-width: 992px) {
 

  footer {
    margin-top: 35px;
  }
  .div-desktop {
    .circle1 {
      top: 15px;
      left: -32px;
    }
    .circle11 {
      top: 15px;
      right: -32px;
    }
    .circle2 {
      bottom: 185px;
      left: 15px;
    }
    .circle21 {
      bottom: 185px;
      right: 15px;
    }
    .circle3 {
      bottom: 40px;
      left: 185px;
    }
    .circle31 {
      bottom: 40px;
      right: 185px;
    }
  }
}
@media screen and (min-width: 1024px) and (max-height: 850px) {
  .div-desktop {
    margin-top: -25px;
  }


  footer {
    margin-top: 35px;
    padding-bottom: 20px;
  }
  .div-desktop {
    .circle1 {
      top: 75px;
      left: -32px;
    }
    .circle11 {
      top: 75px;
      right: -32px;
    }
    .circle2 {
      bottom: 185px;
      left: 15px;
    }
    .circle21 {
      bottom: 185px;
      right: 15px;
    }
    .circle3 {
      bottom: 40px;
      left: 185px;
    }
    .circle31 {
      bottom: 40px;
      right: 185px;
    }
  }
}
@media (min-width: 2560px) {




  footer {
    margin-top: 35px;
  }
  .div-desktop {
    .circle1 {
      top: 385px;
      left: 115px;
    }
    .circle11 {
      top: 385px;
      right: 115px;
    }
    .circle2 {
      bottom: 280px;
      left: 260px;
    }
    .circle21 {
      bottom: 280px;
      right: 260px;
    }
    .circle3 {
      bottom: 100px;
      left: 430px;
    }
    .circle31 {
      bottom: 100px;
      right: 430px;
    }
  }
}

@media (min-width: 1921px) {

  footer {
    margin-top: 15px;
  }
  .div-desktop {
    margin-top: 0px;
    .circle1 {
      top: 240px;
      left: 240px;
    }
    .circle11 {
      top: 240px;
      right: 240px;
    }
    .circle2 {
      bottom: 230px;
      left: 330px;
    }
    .circle21 {
      bottom: 230px;
      right: 330px;
    }
    .circle3 {
      bottom: 75px;
      left: 430px;
    }
    .circle31 {
      bottom: 75px;
      right: 430px;
    }
  }
}

@media (min-width: 1568px) {
  footer {
    margin-top: 130px;
    padding-bottom: 20px;
  }
  .div-desktop {
    .circle1 {
      top: 200px;
      left: 200px;
    }
    .circle11 {
      top: 200px;
      right: 200px;
    }
    .circle2 {
      bottom: 135px;
      left: 330px;
    }
    .circle21 {
      bottom: 135px;
      right: 330px;
    }
    .circle3 {
      bottom: -25px;
      left: 480px;
    }
    .circle31 {
      bottom: -25px;
      right: 480px;
    }
  }
}
@media (max-width: 1567px) {
  footer {
    margin-top: 130px;
    padding-bottom: 20px;
  }
  .div-desktop {
    .circle1 {
      top: 277px;
      left: -92px;
    }
    .circle11 {
      top: 277px;
      right: -92px;
    }
    .circle2 {
      bottom: 135px;
      left: 0px;
    }

    .circle21 {
      bottom: 135px;
      right: 0px;
    }

    .circle3 {
      bottom: -25px;
      left: 115px;
    }
    .circle31 {
      bottom: -25px;
      right: 115px;
    }
  }
}
@media (min-width: 1200px) {
}

@media (min-width: 1440px) and (height: 900px) {
  .div-desktop {
    margin-top: -100px;
  }

  footer {
    margin-top: 45px;
  }
 
  .div-desktop {
    .circle1 {
      top: 150px;
      left: -45px;
    }
    .circle11 {
      top: 150px;
      right: -45px;
    }
    .circle2 {
      bottom: 185px;
      left: 15px;
    }
    .circle21 {
      bottom: 185px;
      right: 15px;
    }
    .circle3 {
      bottom: 40px;
      left: 185px;
    }
    .circle31 {
      bottom: 40px;
      right: 185px;
    }
  }
}
@media (width: 1280px) and (min-height: 800px) {

  footer {
    margin-top: 35px;
  }
  .div-desktop {
    .circle1 {
      top: 75px;
      left: -32px;
    }
    .circle11 {
      top: 75px;
      right: -32px;
    }
    .circle2 {
      bottom: 185px;
      left: 15px;
    }
    .circle21 {
      bottom: 185px;
      right: 15px;
    }
    .circle3 {
      bottom: 40px;
      left: 185px;
    }
    .circle31 {
      bottom: 40px;
      right: 185px;
    }
  }
  .lien-he{
    footer{
      margin-top: -650px;
    }
  }
}
@media (width: 1280px) and (min-height: 950px) {

  .div-desktop {
    margin-top: -65px;
  }


  footer {
    margin-top: 35px;
  }
  .div-desktop {
    .circle1 {
      top: 200px;
      left: -85px;
    }
    .circle11 {
      top: 200px;
      right: -85px;
    }
    .circle2 {
      bottom: 185px;
      left: 15px;
    }
    .circle21 {
      bottom: 185px;
      right: 15px;
    }
    .circle3 {
      bottom: 40px;
      left: 185px;
    }
    .circle31 {
      bottom: 40px;
      right: 185px;
    }
  }
}
@media (min-width: 1920px) {
 

  footer {
    margin-top: 55px;
    padding-bottom: 30px;
  }
  .div-desktop {
    .circle1 {
      top: 170px;
      left: 220px;
    }
    .circle11 {
      top: 170px;
      right: 220px;
    }
    .circle2 {
      bottom: 200px;
      left: 350px;
    }
    .circle21 {
      bottom: 200px;
      right: 350px;
    }
    .circle3 {
      bottom: 30px;
      left: 505px;
    }
    .circle31 {
      bottom: 30px;
      right: 505px;
    }
  }
}

@media (max-width: 1920px) and (height: 1080px) {

 
  footer {
    margin-top: 35px;
  }
  .div-desktop {
    .circle1 {
      top: 310px;
      left: 200px;
    }
    .circle11 {
      top: 310px;
      right: 200px;
    }
    .circle2 {
      bottom: 200px;
      left: 330px;
    }
    .circle21 {
      bottom: 200px;
      right: 330px;
    }
    .circle3 {
      bottom: 65px;
      left: 480px;
    }
    .circle31 {
      bottom: 65px;
      right: 480px;
    }
  }
}
@media (max-width: 1440px) and (height: 1080px) {


  footer {
    margin-top: 35px;
  }
  .div-desktop {
    .circle1 {
      top: 260px;
      left: -100px;
    }
    .circle11 {
      top: 260px;
      right: -100px;
    }
    .circle2 {
      bottom: 220px;
      left: 15px;
    }
    .circle21 {
      bottom: 220px;
      right: 15px;
    }
    .circle3 {
      bottom: 65px;
      left: 145px;
    }
    .circle31 {
      bottom: 65px;
      right: 145px;
    }
  }
}

@media (max-width: 2560px) and (height: 1440px) {


  footer {
    margin-top: 80px;
  }
  .div-desktop {
    .circle1 {
      top: 310px;
      left: 200px;
    }
    .circle11 {
      top: 310px;
      right: 200px;
    }
    .circle2 {
      bottom: 305px;
      left: 330px;
    }
    .circle21 {
      bottom: 305px;
      right: 330px;
    }
    .circle3 {
      bottom: 65px;
      left: 480px;
    }
    .circle31 {
      bottom: 65px;
      right: 480px;
    }
  }
}
@media (max-width: 3840px) and (height: 2160px) {
 

  footer {
    margin-top: 80px;
  }
  .div-desktop {
    .circle1 {
      top: 520px;
      left: -116px;
    }
    .circle11 {
      top: 520px;
      right: -445px;
    }
    .circle2 {
      bottom: 500px;
      left: 80px;
    }
    .circle21 {
      bottom: 500px;
      right: 80px;
    }
    .circle3 {
      bottom: 150px;
      left: 300px;
    }
    .circle31 {
      bottom: 150px;
      right: 300px;
    }
  }
}

/*iPhone 5 & 5S in portrait*/
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) {
  .navflex {
    margin-left: 60px;
  }

  footer .footer .company-info {
    font-size: 10px;
  }
  footer .footer .company-name {
    font-size: 12px;
  }
  .div-mobile {
    img {
      width: 80%;
    }
    .circle-mb-1 {
      right: 15px;
    }
    .circle-mb-2 {
      top: 80px;
      right: 0px;
    }
    .circle-mb-3 {
      top: 165px;
      right: -5px;
    }
    .circle-mb-11 {
      bottom: 80px;
      right: 40px;
    }
    .circle-mb-21 {
      right: 115px;
      bottom: 30px;
      left: unset;
    }
    .circle-mb-31 {
      bottom: 30px;
      left: 15px;
    }
  }
}
/*iPhone 6, 7, & 8 in portrait*/
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
 
  .div-mobile {
    img {
      width: 80%;
    }
    .circle-mb-1 {
      right: 50px;
    }
    .circle-mb-2 {
      top: 80px;
      right: 0px;
    }
    .circle-mb-3 {
      top: 165px;
      right: -5px;
    }
    .circle-mb-11 {
      bottom: 140px;
      right: 30px;
    }
    .circle-mb-21 {
      right: 90px;
      bottom: 65px;
      left: unset;
    }
    .circle-mb-31 {
      bottom: 30px;
      left: 100px;
    }
  }
}
@media only screen and (min-width: 667px) and (orientation: landscape) {
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {

  footer {
    margin-top: -35px;
  }
  .div-mobile {
    img {
      width: 80%;
    }
    .circle-mb-1 {
      right: 100px;
    }
    .circle-mb-2 {
      top: 80px;
      right: 60px;
    }
    .circle-mb-3 {
      top: 165px;
      right: 50px;
    }
    .circle-mb-11 {
      bottom: 180px;
      right: 80px;
    }
    .circle-mb-21 {
      right: 130px;
      bottom: 90px;
      left: unset;
    }
    .circle-mb-31 {
      bottom: 50px;
      left: 80px;
    }
  }
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  // footer {
  //   margin-top: -45px;
  // }
  // .page {
  //   height: 100vh;
  //   background-position-y: 0%;
  // }
  // .div-mobile {
  //   img {
  //     width: 80%;
  //   }
  //   .circle-mb-1 {
  //     right: 100px;
  //   }
  //   .circle-mb-2 {
  //     top: 80px;
  //     right: 35px;
  //   }
  //   .circle-mb-3 {
  //     top: 165px;
  //     right: 30px;
  //   }
  //   .circle-mb-11 {
  //     bottom: 235px;
  //     right: 65px;
  //   }
  //   .circle-mb-21 {
  //     right: 120px;
  //     bottom: 155px;
  //     left: unset;
  //   }
  //   .circle-mb-31 {
  //     bottom: 125px;
  //     left: 55px;
  //   }
  // }
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPad ----------- */

/* Portrait */
@media only screen and (min-width: 768px) and (max-height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  footer {
    margin-top: 75px;
  }
 
  .div-mobile {
    img {
      width: 80%;
    }
    .circle-mb-1 {
      right: 150px;
      top: 100px;
      left: unset;
    }
    .circle-mb-2 {
      top: 40px;
      right: 90px;
      left: unset;
    }
    .circle-mb-3 {
      top: 0px;
      right: 0;
      left: unset;
    }
    .circle-mb-11 {
      top: 0px;
      left: 0;
      right: unset;
    }
    .circle-mb-21 {
      right: unset;
      top: 35px;
      left: 85px;
    }
    .circle-mb-31 {
      top: 95px;
      left: 160px;
      right: unset;
    }
  }
}
/* Landscape */
@media only screen and (min-width: 768px) and (max-height: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
}
/* ----------- iPad Pro 12.9" ----------- */

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {


  .div-desktop {
    .circle1 {
      top: 470px;
      left: -45px;
    }
    .circle11 {
      top: 470px;
      right: -45px;
    }
  }
  footer {
    margin-top: 80px;
    padding-bottom: 50px;
  }
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- Non-Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  // footer {
  //   margin-top: -45px;
  // }
  // .page {
  //   height: 100vh;
  //   background-position-y: 0%;
  // }
}
/* 2778x1284 pixels at 458ppi */
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .div-mobile {
    img {
      width: 80%;
    }
    .circle-mb-1 {
      right: 150px;
      top: 100px;
      left: unset;
    }
    .circle-mb-2 {
      top: 40px;
      right: 90px;
      left: unset;
    }
    .circle-mb-3 {
      top: 0px;
      right: 0;
      left: unset;
    }
    .circle-mb-11 {
      top: 0px;
      left: 0;
      right: unset;
    }
    .circle-mb-21 {
      right: unset;
      top: 35px;
      left: 85px;
    }
    .circle-mb-31 {
      top: 95px;
      left: 160px;
      right: unset;
    }
  }
}

@media (width: 1366px) and (height: 768px) {
  .lien-he{
    footer{
      margin-top: -640px;
    }
  }

  .div-desktop {
    margin-top: -150px;
  }

  footer {
    margin-top: 108px;
  }

  .div-desktop {
    .circle1 {
      top: 150px;
      left: -45px;
    }
    .circle11 {
      top: 150px;
      right: -45px;
    }
    .circle2 {
      bottom: 80px;
      left: 15px;
    }
    .circle21 {
      bottom: 80px;
      right: 15px;
    }
    .circle3 {
      bottom: -75px;
      left: 185px;
    }
    .circle31 {
      bottom: -75px;
      right: 185px;
    }
  }
}
