//- ----------------------------------------------
//- Variables
//- ----------------------------------------------

// Colors
//
// Gray and brand colors for use across the theme.

$primary: #004899; //#f5b900
$primary-menu: #ffdd00; //#f5b900
$secondary: #6c702f; //003875

$gray-light: #333333;
$gray-lighter: #f1f1f1;
$gray-lightest: #fcfcfc;

$brand-primary: #1a3c34;
$brand-secondary: #0091d2; //#2a1e16;

$brand-success: #8add66;
$brand-info: #2ba3e1;
$brand-warning: #F5a623;
$brand-danger: #ea4335;
$brand-title: #004899;
$brand-input-date: #323232;
$brand-inverse: $brand-secondary;
$black: #000000;
// Body
//
// Settings for the `<body>` element.

$body-bg: #f4f3f0;
$body-color: $black;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1568px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1600px,
);

//$custom-control-indicator-checked-color: $primary;
//$custom-control-indicator-checked-border-color: $primary;
$custom-control-indicator-disabled-bg: $gray-light;
// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-base: "Lato", "Open Sans", sans-serif;
$font-family-primary: "Lato", sans-serif;
//$font-family-secondary: 'Yellowtail', cursive;

$table-border-color: #acacac;
// Footer
$footer-bg-color: $brand-primary;
$footer-widget-title-color: $brand-primary;
$footer-widget-title-number-color: #fff;
$footer-widget-body-color: #fff;

$footer-copyright-bg-color: rgba(0, 0, 0, 0.2);
$footer-border-top-color: rgba(255, 255, 255, 0.1);
$footer-txt-color: #fff;
$footer-link-hover-color: #fff;

//$enable-shadows: false;

$fa-font-path: "../fonts";
//$flag-icon-css-path: '../images/flags';
$paragraph-margin-bottom: 1rem;
$font-size-base: .875rem;
