
@import "variables";
@import "../vendor/font-awesome/scss/font-awesome";

/*- Bootstrap
/*- ---------------------------------------------- */
// Import Bootstrap variables after customization for use below
@import "../vendor/bootstrap/scss/functions"; // from bootstrap node_modules
@import "../vendor/bootstrap/scss/variables"; // from bootstrap node_modules
@import "../vendor/bootstrap/scss/bootstrap";

/*- Template
/*- ---------------------------------------------- */
@import "template/template";
//@import "custom";

