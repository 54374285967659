﻿@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
@import url("../fonts/SVNLifehackBasic/stylesheet.css");
@import url("../fonts/SVNFreude/stylesheet.css");
@import url("../fonts/Montserrat/stylesheet.css");
@import url("../fonts/icomoon/style.css");

$colorPrimary: #084f32;

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #000;
  line-height: 1.4;
  background-color: #fff;
  font-weight: 500;
}

html,
body {
  height: 100%;
}

img {
  max-width: 100%;
}

.hidden {
  overflow: hidden;
}
a:hover {
  text-decoration: none !important;
}
.page {
  background-image: url(../images/bg-mb.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @include media-breakpoint-up(md) {
    background-image: url(../images/bg-lg.jpg);
  }

  @include media-breakpoint-up(lg) {
    background-image: url(../images/bg-lg.jpg);
  }

  @include media-breakpoint-up(xl) {
    background-image: url(../images/bg-lg.jpg);
  }
}
header {
  background-color: #339966;

  .top {
    background: $colorPrimary;
    padding: 12px 0;

    ul {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0;
      padding: 0;

      li {
        color: #fff;
        list-style: none;
        font-weight: 600;
        font-size: 12px;
        margin-right: 5px;

        img {
          margin-right: 3px;
        }
      }
    }
  }

  .navbar {
    padding: 0;
    justify-content: center;
  }

  .logo {
    position: absolute;
    left: 0;
    top: -44px;
    z-index: 1024;
  }

  .navbar-nav {
    li {
      margin: 0 25px;

      a {
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        padding: 15px 0;

        img {
          margin-bottom: 7px;
        }
      }
    }
  }
}

.tree {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.trang-chu {
  @media (max-width: 1920px) and (height: 1080px) {
    .page {
      background-position: center center;
    }
  }
  @media (min-width: 1921px) {
    .page {
      background-position: 0 -115px;
    }
    .group {
      min-height: auto !important;
    }
  }
  @media (width: 1366px) and (height: 768px) {
    .page {
      background-position: center center;
    }
  }
  footer {
    position: absolute;
    bottom: 0;
    padding-bottom: 20px;
    width: 97%;
  }
}
.main-page {
  text-align: center;
  position: relative;
  @include media-breakpoint-up(md) {
    height: calc(100vh - 131px);
    padding-top: 30px;
  }

  @include media-breakpoint-up(lg) {
    height: calc(100vh - 131px);
    padding-top: 30px;
  }

  @include media-breakpoint-up(xl) {
    height: calc(100vh - 131px);
    padding-top: 30px;
  }
  h2.title-slogon {
    color: #1991d6;
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;

    font-family: "SVN-Freude";
    @include media-breakpoint-up(md) {
      font-family: "SVN-Freude";
    }

    @include media-breakpoint-up(lg) {
      font-family: "SVN-Freude";
      font-size: 38px;
    }

    @include media-breakpoint-up(xl) {
      font-family: "SVN-Freude";
      font-size: 38px;
    }
  }

  .group {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    @include media-breakpoint-up(md) {
      min-height: auto;
    }

    @include media-breakpoint-up(lg) {
      min-height: auto;
    }

    @include media-breakpoint-up(xl) {
      min-height: auto;
    }
  }

  .circle {
    background: url("../images/circle.png");
    width: 134px;
    height: 134px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    color: #09609e;
    text-align: center;
    position: absolute;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    img {
      position: absolute;
      left: 114px;
      max-width: none;
    }
    a {
      z-index: 999;
    }
  }

  .pst1 {
    top: 292px;
    left: -23px;
  }

  .pst2 {
    top: 423px;
    left: 100px;
  }

  .pst3 {
    bottom: 270px;
    left: 253px;

    img {
      top: 54px;
    }
  }
}

.navbar-toggler {
  position: relative;
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 2px;
  height: 30px;
  width: 30px;

  .icon {
    transition-duration: 0.5s;
    position: absolute;
    height: 2px;
    width: 20px;
    background-color: #fff;

    &::before {
      transition-duration: 0.5s;
      position: absolute;
      height: 2px;
      width: 20px;
      background-color: #fff;
      content: "";
      top: -6px;
      left: 0px;
    }

    &:after {
      transition-duration: 0.5s;
      position: absolute;
      height: 2px;
      width: 20px;
      background-color: #fff;
      content: "";
      top: 6px;
      left: 0px;
    }
  }
}

@media (max-width: 991px) {
  header {
    .top {
      height: 35px;

      ul {
        display: none;
      }
    }

    .navflex {
      .top {
        height: auto;
        background-color: transparent;
        display: block !important;

        ul {
          display: flex;
          flex-direction: column;

          li:first-child {
            padding-left: 15px;
            text-transform: uppercase;
          }

          li {
            font-size: 11px;

            img {
              width: 8px;
              position: relative;
              top: -1px;
            }
          }
        }
      }

      .navigation {
        position: fixed;
        width: 100%;
        background-color: rgba($colorPrimary, $alpha: 0.9);
        left: 0;
        top: 119px;
        z-index: 9;

        ul {
          li:last-child {
            padding-bottom: 15px;
          }
        }
      }

      .navbar-toggler {
        position: absolute;
        right: 0;
        top: 10px;
      }
    }
  }
  .navbar-nav li a img {
    width: 25px;
  }
  .hidden {
    .navbar-toggler {
      .icon {
        background-color: transparent;

        &::before {
          transform: rotateZ(45deg);
          top: 0;
        }

        &::after {
          transform: rotateZ(-45deg);
          top: 0;
        }
      }
    }
  }
}

.block-mobile {
  display: none;
}
footer {
  margin-top: 0px;
  @include media-breakpoint-up(lg) {
    margin-top: 60px;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 60px;
  }

  .footer {
    text-align: center;

    .company-name {
      font-size: 14px;
      font-weight: bold;
      color: $white;
      @include media-breakpoint-up(md) {
        font-size: 14px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 15px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 15px;
      }
    }
    .company-info {
      font-size: 13px;
      color: $white;
      font-family: "Montserrat-Medium";
      font-weight: bold;
      span {
        margin-right: 10px;
        i {
          color: #3ad65b;
        }
      }
      @include media-breakpoint-up(md) {
        font-size: 13px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 12px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 12px;
      }
    }
  }
}
@media (max-width: 575px) {
  header {
    .logo {
      left: -15px;
      top: -15px;

      .block-mobile {
        display: block;
      }

      .block-pc {
        display: none;
      }
    }
  }
}

.div-desktop {
  height: 70vh;
  .circle1 {
    position: absolute;
    top: 200px;
    left: 200px;
    @include media-breakpoint-up(md) {
      top: 100px;
      left: -45px;
    }

    @include media-breakpoint-up(lg) {
      top: 100px;
      left: -45px;
    }

    @include media-breakpoint-up(xl) {
      top: 200px;
      left: 200px;
    }
  }
  .circle2 {
    position: absolute;
    bottom: 200px;
    left: 330px;
    @include media-breakpoint-up(md) {
      bottom: 205px;
      left: 56px;
    }

    @include media-breakpoint-up(lg) {
      bottom: 205px;
      left: 56px;
    }

    @include media-breakpoint-up(xl) {
      bottom: 200px;
      left: 330px;
    }
  }
  .circle3 {
    position: absolute;
    bottom: 30px;
    left: 480px;
    @include media-breakpoint-up(md) {
      bottom: 75px;
      left: 205px;
    }

    @include media-breakpoint-up(lg) {
      bottom: 75px;
      left: 205px;
    }

    @include media-breakpoint-up(xl) {
      bottom: 30px;
      left: 480px;
    }
  }
  .circle11 {
    position: absolute;
    top: 200px;
    right: 200px;
    @include media-breakpoint-up(md) {
      top: 200px;
      left: 200px;
    }

    @include media-breakpoint-up(lg) {
      top: 100px;
      right: -45px;
    }

    @include media-breakpoint-up(xl) {
      top: 200px;
      right: 200px;
    }
  }
  .circle21 {
    position: absolute;
    bottom: 200px;
    right: 330px;
    @include media-breakpoint-up(md) {
      bottom: 200px;
      right: 330px;
    }

    @include media-breakpoint-up(lg) {
      bottom: 205px;
      right: 56px;
    }

    @include media-breakpoint-up(xl) {
      bottom: 200px;
      right: 330px;
    }
  }
  .circle31 {
    position: absolute;
    bottom: 30px;
    right: 480px;
    @include media-breakpoint-up(md) {
      bottom: 30px;
      right: 480px;
    }

    @include media-breakpoint-up(lg) {
      bottom: 75px;
      right: 205px;
    }

    @include media-breakpoint-up(xl) {
      bottom: 30px;
      right: 480px;
    }
  }
}

.div-mobile {
  height: 70vh;
  .circle-mb-1 {
    position: absolute;
    top: 0px;
    right: 90px;
    @include media-breakpoint-up(md) {
      top: 100px;
      left: -45px;
    }

    @include media-breakpoint-up(lg) {
      top: 100px;
      left: -45px;
    }

    @include media-breakpoint-up(xl) {
      top: 200px;
      left: 200px;
    }
  }
  .circle-mb-2 {
    position: absolute;
    top: 90px;
    right: 30px;
    @include media-breakpoint-up(md) {
      bottom: 225px;
      left: 56px;
    }

    @include media-breakpoint-up(lg) {
      bottom: 225px;
      left: 56px;
    }

    @include media-breakpoint-up(xl) {
      bottom: 200px;
      left: 330px;
    }
  }
  .circle-mb-3 {
    position: absolute;
    top: 195px;
    right: 5px;
    @include media-breakpoint-up(md) {
      bottom: 45px;
      left: 205px;
    }

    @include media-breakpoint-up(lg) {
      bottom: 45px;
      left: 205px;
    }

    @include media-breakpoint-up(xl) {
      bottom: 30px;
      left: 480px;
    }
  }
  .circle-mb-11 {
    position: absolute;
    bottom: 115px;
    right: 40px;
    @include media-breakpoint-up(md) {
      top: 200px;
      left: 200px;
    }

    @include media-breakpoint-up(lg) {
      top: 100px;
      right: -45px;
    }

    @include media-breakpoint-up(xl) {
      top: 200px;
      left: 200px;
    }
  }
  .circle-mb-21 {
    position: absolute;
    bottom: 50px;
    right: 130px;
    @include media-breakpoint-up(md) {
      bottom: 200px;
      right: 330px;
    }

    @include media-breakpoint-up(lg) {
      bottom: 225px;
      right: 56px;
    }

    @include media-breakpoint-up(xl) {
      bottom: 200px;
      right: 330px;
    }
  }
  .circle-mb-31 {
    position: absolute;
    bottom: 55px;
    left: 65px;
    @include media-breakpoint-up(md) {
      bottom: 30px;
      right: 480px;
    }

    @include media-breakpoint-up(lg) {
      bottom: 45px;
      right: 205px;
    }

    @include media-breakpoint-up(xl) {
      bottom: 30px;
      right: 480px;
    }
  }
}

/* Shine */
/* Shine */
// .hover15 figure {
// 	position: relative;
// }
// .hover15 figure::before {
// 	position: absolute;
// 	top: 0;
// 	left: -75%;
// 	z-index: 2;
// 	display: block;
// 	content: '';
// 	width: 50%;
// 	height: 100%;
// 	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
// 	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
// 	-webkit-transform: skewX(-25deg);
// 	transform: skewX(-25deg);
// }
// .hover15 figure:hover::before {
// 	-webkit-animation: shine .75s;
// 	animation: shine .75s;
// }
// @-webkit-keyframes shine {
// 	100% {
// 		left: 125%;
// 	}
// }
// @keyframes shine {
// 	100% {
// 		left: 125%;
// 	}
// }

/* Circle */
.hover15 figure {
  position: relative;
}
.hover15 figure::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}
.hover15 figure:hover::before {
  -webkit-animation: circle 0.75s;
  animation: circle 0.75s;
}
@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
// section

.w-1170px {
  @include media-breakpoint-up(md) {
  }

  @include media-breakpoint-up(lg) {
    width: 1170px !important;
  }

  @include media-breakpoint-up(xl) {
    width: 1170px !important;
  }
}
//////Project
.project {
  min-height: 75vh;
  .div-banner {
    margin: 0 -15px;
    .responsive {
      width: 100%;
      height: auto;
    }
  }
  .div-title {
    .header-title {
      font-size: 24px;
      font-weight: bold;
      color: #0099cc;
      font-family: "Open Sans", sans-serif;

      @include media-breakpoint-up(md) {
      }

      @include media-breakpoint-up(lg) {
        font-size: 30px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 30px;
      }
    }
  }
  .thumbnail-classic {
    .div-images {
      min-height: 180px;
      background-size: cover;
      background-position: center center;
    }
    .caption {
      color: #333333 !important;

      h5 {
        margin: 20px 0 10px;
        .thumbnail-classic-title {
          font-size: 16px;
          font-weight: bold;
          color: #333333 !important;
          font-family: "Open Sans", sans-serif;
          text-transform: none;
          @include media-breakpoint-up(md) {
          }

          @include media-breakpoint-up(lg) {
            font-size: 18px;
          }

          @include media-breakpoint-up(xl) {
            font-size: 18px;
          }
        }
      }
      p {
        font-size: 14px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 20px;
      }
    }
  }
  .carousel-parent {
    .slick-track {
      margin-bottom: 10px;
      .item .item1 {
        width: 600px;
        height: 355px;
        background-size: cover;
      }
    }
  }
  .slick-slider-child {
    .item-inner {
      height: 98px;
      background-size: cover;
    }
  }
  .single-product {
    h2 {
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
    }
    p {
      font-size: 12px;
    }
  }
  .div-product-other {
    h2 {
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
    }
    p {
      font-size: 12px;
    }
    h5 a {
      font-size: 14px;
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
    }
  }
}
.breadcrumb {
  padding: 20px 0;
  margin-bottom: 1.2rem;
  list-style: none;
  background-color: transparent;
  .breadcrumb-item {
    a {
      font-size: 13px;
      color: #999999;
    }
  }
}
#ui-to-top {
  border-radius: 50%;
  background-color: #fff;
  border-color: #33ff66;
  border: 1px solid #33ff66;
  opacity: 1;
}
.fa-angle-up:before {
  content: "\f106";
  line-height: 45px;
  color: #33ff66;
  font-size: 30px;
}
// .page-item:first-child {
//   text-indent: -9999px;
//     line-height: 0;
//    font-size: 0;
//   a::after {
//     content: "<<";
//     text-indent: 0;
//     display: block;
//     line-height: initial;
//   }
// }
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #40840c;
  border-color: #40840c;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #40840c;
  background-color: #fff;
  border: 1px solid #40840c;
}
.navbar-nav {
  li.nav-item {
    transition: 0.3s ease;
    background: transparent;
    color: #ffffff;
    font-size: 20px;
    text-decoration: none;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    padding: 0px 0;
    margin: 0 20px;
    &.active {
      border-top: 2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      padding: 0px 0;

      a {
        color: #3dea61;
        span::before {
          color: #3dea61;
        }
      }
    }
    &:hover {
      border-top: 2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      padding: 0px 0;
    }
  }
}

.div-submenu {
  //  margin: 0 -15px;
  //   div [class^="col-"] {
  //     margin: 0 -15px;

  //   }
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  @include media-breakpoint-up(md) {
    display: flex !important;
  }

  @include media-breakpoint-up(lg) {
    margin-top: -35px;
    display: flex !important;
  }

  @include media-breakpoint-up(xl) {
    margin-top: -35px;
    display: flex !important;
  }
  .product-info {
    padding: 10px 0;
    background-image: url(../images/bg-button.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 70px;
    display: flex;
    border-right: 1px solid rgba(0, 51, 0, 0.2);
    justify-content: center;
  }
  .title-product {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    align-self: center;

    @include media-breakpoint-up(md) {
    }

    @include media-breakpoint-up(lg) {
      margin: 5px 20px;
      font-size: 16px;
    }

    @include media-breakpoint-up(xl) {
      margin: 5px 20px;
      font-size: 16px;
    }
  }
}
.about-page {
  .icon-about {
    margin: 30px 0 20px 0;
  }
  .div-content {
    p {
      text-align: justify;
    }
  }

  #tam-nhin .item-product {
  
    .product-img {
      padding: 0px;
      text-align: center;
      background-color: #fff;
    }
  }
  .item-product {
    box-shadow: 2px 0px 5px 0px #cecece;

    margin: 0 0px 20px;
    .product-img {
      padding: 20px;
      text-align: center;
      background-color: #fff;
    }
    .product-info {
      padding: 10px 0;
      background-image: url(../images/bg-button.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 82px;
      display: flex;
      justify-content: center;
    }
    .title-product {
      font-family: "Open Sans", sans-serif;
      font-weight: bold;
      font-size: 13px;
      line-height: 21px;
      color: #fff;
      text-align: center;
      margin: 0px;
      align-self: center;
    }
  }
  .div-content-background-img {
    background-image: unset;
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
    .div-image {
      margin: 15px 15px 0 0;
      float: left;
    }

    @include media-breakpoint-up(md) {
    }

    @include media-breakpoint-up(lg) {
      background-image: url(../images/Image-bg-customer.png);
    }

    @include media-breakpoint-up(xl) {
      background-image: url(../images/Image-bg-customer.png);
    }
  }
  footer {
    margin-top: -550px;
  }
}
#xay-dung,
#cong-nghe {
  .product-img {
    padding: 0px;
  }
}
.contact-page {
  .div-title {
    .header-title {
      font-size: 20px;
    }
  }
}
.div-product-other {
  background-color: rgba(249, 251, 251, 1);
}
.slick-slider-child .slick-current .item-inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  box-shadow: inset 0 0 0 1px #339966;
}
.div-content {
  padding: 0 20px;
}
.div-submenu a {
  @include media-breakpoint-up(md) {
  }

  @include media-breakpoint-up(lg) {
    &:hover {
      -webkit-animation-name: hvr-pulse-grow;
      animation-name: hvr-pulse-grow;
      -webkit-animation-duration: 0.3s;
      animation-duration: 0.3s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      // -webkit-animation-iteration-count: infinite;
      // animation-iteration-count: infinite;
      -webkit-animation-direction: alternate;
      animation-direction: alternate;
    }
  }
  @include media-breakpoint-up(xl) {
    &:hover {
      -webkit-animation-name: hvr-pulse-grow;
      animation-name: hvr-pulse-grow;
      -webkit-animation-duration: 5s;
      animation-duration: 0.3s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      //-webkit-animation-iteration-count: infinite;
      // animation-iteration-count: infinite;
      -webkit-animation-direction: alternate;
      animation-direction: alternate;
    }
  }
}
.hvr-pulse-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
@-webkit-keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.slick-prev,
.slick-next {
  background-color: transparent;

  top: 40%;
  height: 40px;
  width: 40px;
}
.slick-next:before {
  content: url("../images/icon/Arrow-phai.png");
}
.slick-prev:before {
  content: url("../images/icon/Arrow-trai.png");
}
.btn-contact {
  background-image: url(../images/bg-button.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 !important;
  border: 0;
  &:hover {
    -webkit-animation-name: hvr-pulse-grow;
    animation-name: hvr-pulse-grow;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
  }
}
.header-title {
  font-weight: bold;
  color: #0099cc;
  font-family: "Open Sans", sans-serif;
}
// .about-page{
//   background-color: #fff;
// }

.group {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.circle {
  background-image: url("../images/circle.png");
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  color: #006699;
  text-align: center;
  position: absolute;
  font-size: 10px;
  font-weight: bold;
  background-size: cover;
  background-repeat: no-repeat;
  img {
    position: absolute;
    left: 114px;
    max-width: none;
  }

  &.pst1 {
    top: 10px;
    right: 150px;
  }
  &.pst2 {
    top: 100px;
    right: 60px;
  }

  &.pst3 {
    top: 220px;
    right: 30px;
  }

  &.pst4 {
    top: 350px;
    right: 30px;
  }
  &.pst5 {
    top: 460px;
    right: 110px;
  }
  &.pst6 {
    top: 560px;
    right: 210px;
  }

  span {
    align-self: center;
  }
}
.btn-nangluc {
  &.active {
    .circle {
      width: 110px;
      height: 110px;
      color: #33cc66;
    }
  }
}
.main-page {
  .div-cus-desktop {
    .home1 {
      top: 125px;
      left: 205px;
    }
    .home11 {
      top: 125px;
      right: 205px;
      img {
        left: -177px;
      }
    }

    .home2 {
      top: 250px;
      left: 350px;
    }
    .home21 {
      top: 250px;
      right: 350px;
      img {
        left: -177px;
      }
    }
    .home3 {
      img {
        top: 54px;
      }
      top: 375px;
      left: 495px;
    }
    .home31 {
      img {
        top: 54px;
        left: -80px;
      }
      top: 375px;
      right: 495px;
    }

    @media only screen and (width: 1280px) and (min-height: 800px) {
      .circle {
        width: 110px;
        height: 110px;
        font-size: 13px;
        img {
          left: 90px;
        }
      }
      .home1 {
        top: 10px;
        left: 50px;
      }
      .home11 {
        top: 10px;
        right: 50px;
        img {
          left: -177px;
        }
      }
      .home2 {
        top: 145px;
        left: 90px;
      }
      .home21 {
        top: 145px;
        right: 90px;
        img {
          left: -177px;
        }
      }
      .home3 {
        img {
          top: 54px;
        }
        top: 270px;
        left: 170px;
      }

      .home31 {
        img {
          top: 54px;
          left: -80px;
        }
        top: 270px;
        right: 170px;
      }
    }

    @media only screen and (width: 1280px) and (min-height: 950px) {
      .circle {
        width: 110px;
        height: 110px;
        font-size: 13px;
        img {
          left: 90px;
        }
      }
      .home1 {
        top: 160px;
        left: 0px;
      }
      .home11 {
        top: 160px;
        right: 0px;
        img {
          left: -177px;
        }
      }
      .home2 {
        top: 280px;
        left: 90px;
      }
      .home21 {
        top: 280px;
        right: 90px;
        img {
          left: -177px;
        }
      }
      .home3 {
        img {
          top: 54px;
        }
        top: 380px;
        left: 200px;
      }

      .home31 {
        img {
          top: 54px;
          left: -80px;
        }
        top: 380px;
        right: 200px;
      }
    }

    @media only screen and (width: 1366px) and (height: 768px) {
      .circle img {
        left: 95px;
      }
      .circle {
        width: 110px;
        height: 110px;
        font-size: 13px;
      }
      .home1 {
        top: 50px;
        left: 20px;
      }
      .home11 {
        top: 50px;
        right: 20px;
        img {
          left: -177px;
        }
      }
      .home21 {
        top: 160px;
        right: 100px;
        img {
          left: -177px;
        }
      }
      .home2 {
        top: 160px;
        left: 100px;
      }
      .home3 {
        img {
          top: 54px;
        }
        top: 245px;
        left: 220px;
      }
      .home31 {
        img {
          top: 54px;
          left: -80px;
        }
        top: 245px;
        right: 220px;
      }
    }

    @media (min-width: 1440px) and (height: 900px) {
      .main-page .circle {
        width: 110px;
        height: 110px;
        font-size: 13px;
      }
      .home1 {
        top: 300px;
        left: -170px;
      }
      .home11 {
        top: 300px;
        right: -170px;
        img {
          left: -177px;
        }
      }
      .home2 {
        top: 460px;
        left: -20px;
      }
      .home21 {
        top: 460px;
        right: -20px;
        img {
          left: -177px;
        }
      }
      .home3 {
        img {
          top: 54px;
        }
        top: 615px;
        left: 145px;
      }

      .home31 {
        img {
          top: 54px;
          left: -80px;
        }
        top: 615px;
        right: 145px;
      }
    }

    @media (min-width: 2304px) and (height: 1440px) {
      .home1 {
        top: 455px;
        left: 205px;
      }
      .home11 {
        top: 455px;
        right: 205px;
        img {
          left: -177px;
        }
      }
      .home2 {
        top: 625px;
        left: 315px;
      }
      .home21 {
        top: 625px;
        right: 315px;
        img {
          left: -177px;
        }
      }
      .home3 {
        img {
          top: 54px;
        }
        top: 780px;
        left: 420px;
      }

      .home31 {
        img {
          top: 54px;
          left: -80px;
        }
        top: 780px;
        right: 420px;
      }
    }

    @media (max-width: 1920px) and (height: 1080px) {
      .home1 {
        top: 240px;
        left: 215px;
      }
      .home11 {
        top: 240px;
        right: 215px;
        img {
          left: -177px;
        }
      }
      .home2 {
        top: 375px;
        left: 320px;
      }
      .home21 {
        top: 375px;
        right: 320px;
        img {
          left: -177px;
        }
      }
      .home3 {
        img {
          top: 54px;
        }
        top: 485px;
        left: 460px;
      }
      .home31 {
        img {
          top: 54px;
          left: -80px;
        }
        top: 485px;
        right: 460px;
      }
    }
    /* ----------- iPad Pro 12.9" ----------- */

    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      .home1 {
        top: 550px;
        left: -15px;
      }
      .home11 {
        top: 550px;
        right: -15px;
        img {
          left: -177px;
        }
      }
      .home2 {
        top: 800px;
        left: 65px;
      }
      .home21 {
        top: 800px;
        right: 65px;
        img {
          left: -177px;
        }
      }
      .home3 {
        img {
          top: 54px;
        }
        top: 725px;
        left: 175px;
      }
      .home31 {
        img {
          top: 54px;
          left: -80px;
        }
        top: 725px;
        right: 175px;
      }
    }

    @media only screen and (width: 1568px) and (width: 1280px) {
      .home1 {
        top: 355px;
        left: -195px;
      }
      .home11 {
        top: 355px;
        right: -195px;
        img {
          left: -177px;
        }
      }
      .home21 {
        top: 530px;
        right: -40px;
        img {
          left: -177px;
        }
      }
      .home2 {
        top: 530px;
        left: -40px;
      }
      .home3 {
        img {
          top: 54px;
        }
        top: 715px;
        left: 145px;
      }
      .home31 {
        img {
          top: 54px;
          left: -80px;
        }
        top: 715px;
        right: 145px;
      }
    }

    @media only screen and (max-width: 1440px) and (min-width: 1367px) {
      .home1 {
        top: 85px;
        left: 20px;
      }
      .home11 {
        top: 85px;
        right: 20px;
        img {
          left: -177px;
        }
      }
      .home21 {
        top: 170px;
        right: 100px;
        img {
          left: -177px;
        }
      }
      .home2 {
        top: 170px;
        left: 100px;
      }
      .home3 {
        img {
          top: 54px;
        }
        top: 350px;
        left: 170px;
      }
      .home31 {
        img {
          top: 54px;
          left: -80px;
        }
        top: 350px;
        right: 170px;
      }
    }

    // @media  screen and (max-height: 933) {
    //   .group {
    //     min-height: 110vh;
    //   }
    // }
    @media screen and (max-width: 1567px) and (min-width: 1440px) {
      .home1 {
        top: 80px;
        left: -40px;
      }
      .home11 {
        top: 80px;
        right: -40px;
        img {
          left: -177px;
        }
      }
      .home21 {
        top: 230px;
        right: 45px;
        img {
          left: -177px;
        }
      }
      .home2 {
        top: 230px;
        left: 45px;
      }
      .home3 {
        img {
          top: 54px;
        }
        top: 350px;
        left: 170px;
      }
      .home31 {
        img {
          top: 54px;
          left: -80px;
        }
        top: 350px;
        right: 170px;
      }
    }
    @media screen and (max-height: 699px) and (min-height: 501px) {
      .group {
        min-height: 120vh;
      }
    }
    @media (max-height: 500px), screen and (orientation: portrait) {
      .group {
        min-height: auto;
      }
    }
  }

  .div-cus-mobile {
    @media only screen and (max-width: 768px) {
      .circle {
        width: 90px;
        height: 90px;
        font-size: 8px;
      }
      .home1 {
        top: 0px;
        right: 45px;
      }
      .home2 {
        top: 105px;
        right: 5px;
      }
      .home3 {
        top: 210px;
        right: 0px;
      }
      .home11 {
        top: 315px;
        right: 5px;
      }
      .home21 {
        top: 420px;
        right: 45px;
      }
      .home31 {
        top: 495px;
        right: 160px;
      }
    }
  }
}
@media screen and (max-height: 699px) and (min-height: 501px) {
  .trang-chu {
    .main-page {
      height: 133vh;
      .group {
        min-height: 100vh;
      }
    }
    footer {
     
      padding-bottom: 0px;
     
    }
  }
}
.effect {
  &::after {
    border-radius: 50%;
    animation-name: pulse;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
  }
  &:hover::after {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
  }
  80% {
    box-shadow: 0 0 0 25px rgba(37, 211, 102, 0);
  }
}

@media only screen and (max-width: 1568px) {
  .navbar-nav li.nav-item {
    font-size: 15px;
    margin: 0px 10px;
  }
}

@media (width: 1366px) and (max-height: 768px) {
  .navflex {
    margin-left: 10%;
  }
  .project .div-title .header-title {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: unset;
  }
  .about-page .item-product .title-product {
    font-size: 13px;
    line-height: 18px;

    margin: 0px;
  }
  .project {
    min-height: 75vh;
  }
}
.nav-link {
  span {
    font-size: 35px;
    margin-bottom: 2px;
  }
}
.group-mobile {
  .circle {
    position: unset;
    width: 90px;
    height: 90px;
  }
  .btn-nangluc.active .circle {
    width: 92px;
    height: 92px;
    color: #33cc66;
  }
}
.thuc-hien-du-an {
  .nang-luc {
    .item-product {
      box-shadow: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .lien-he,
  .nang-luc,
  .gioi-thieu,
  .du-an,
  .giai-phap-cong-nghe,
  .hop-tac {
    .page {
      background-image: none;
    }
  }
}

@media screen and (max-height: 767px) {
  .nang-luc {
    .group {
      min-height: 125vh;
    }
  }
  .lien-he {
    footer {
      margin-top: -580px;
    }
  }
}
.div-image {
  margin-bottom: 20px;
}
.div-info{
  height: 61px;
  margin-top: 15px;
  display: flex;
  p{
    align-self: center;
  }
}
.listing-block {
  background: #fff;
  height: 500px;
  padding-top: 20px;
}
.media {
  background: #fff;
  position: relative;
  margin-bottom: 15px;
}
.media img {
  width: 300px;
  margin: 0;
  height: 138px;
}
.media-body {
  border-left: 0;
  height: 138px;
}
.media .price {
  float: left;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: #000 !important;
}
.media .price small,
small {
  display: block;
  font-size: 11px;
  color: #999999;
  font-style: italic;
}
.media .address {
  float: left;
  width: 100%;
  font-size: 12px;
  margin-top: 5px;
  color: #000;
}
.title-detail {
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: #000 !important;
}
.breadcrumbs-custom-path {
  background-color: transparent;
  justify-content: flex-start;
  color: #999999 !important;
}
.breadcrumbs-custom-path li {
  font-size: 12px;
  color: #999999 !important;
}
.lien-he {
  .form-control {
    max-width: 303px;
  }
}
.fa-angle-up:before {
  line-height: 25px;

  font-size: 20px;
  @include media-breakpoint-up(lg) {
    font-size: 30px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 30px;
  }
}
.ui-to-top {
  right: 10px;
  bottom: 0px;

  width: 30px;
  height: 30px;
  display: block !important;
  @include media-breakpoint-up(lg) {
    width: 53.33333px;
    height: 53.33333px;
  }

  @include media-breakpoint-up(xl) {
    width: 53.33333px;
    height: 53.33333px;
  }
}

.content-nang-luc {
  max-height: none;
  overflow: unset;
  overflow-x: unset;
  @include media-breakpoint-up(lg) {
    max-height: 75vh;
    margin-top: 0px;

    padding: 20px;

    overflow: auto;
  }

  @include media-breakpoint-up(xl) {
    max-height: 75vh;
    margin-top: 0px;

    padding: 20px;

    overflow: auto;
  }
}

.main-page .div-cus-desktop {
  @media screen and (max-width: 1439px) and (min-width: 1281px) {
    .circle {
      width: 100px;
      height: 100px;
      font-size: 10px;
      img {
        left: 85px;
      }
    }
    .home1 {
      top: 85px;
      left: 20px;
    }
    .home11 {
      top: 85px;
      right: 20px;
      img {
        left: -177px;
      }
    }
    .home21 {
      top: 170px;
      right: 100px;
      img {
        left: -177px;
      }
    }
    .home2 {
      top: 170px;
      left: 100px;
    }
    .home3 {
      img {
        top: 54px;
      }
      top: 255px;
      left: 170px;
    }
    .home31 {
      img {
        top: 54px;
        left: -80px;
      }
      top: 255px;
      right: 170px;
    }
  }

  @media screen and (max-width: 1279px) and (min-width: 991px) {
    .circle {
      width: 100px;
      height: 100px;
      font-size: 10px;
      img {
        left: 85px;
      }
    }
    .home1 {
      top: 85px;
      left: 20px;
    }
    .home11 {
      top: 85px;
      right: 20px;
      img {
        left: -177px;
      }
    }
    .home21 {
      top: 170px;
      right: 100px;
      img {
        left: -177px;
      }
    }
    .home2 {
      top: 170px;
      left: 100px;
    }
    .home3 {
      img {
        top: 54px;
      }
      top: 255px;
      left: 170px;
    }
    .home31 {
      img {
        top: 54px;
        left: -80px;
      }
      top: 255px;
      right: 170px;
    }
  }
}
@media (min-width: 2304px) and (height: 1440px) {
  .trang-chu .page {
    background-position: center center;
  }
}
.nang-luc {
  .page {
    background-image: url(/Themes/ThienMinh/Content/images/bg-nang-luc1.png);
    background-size: cover;
    background-position: 0 -110px;
    min-height: 100vh;
  }
  footer {
    background-image: url(/Themes/ThienMinh/Content/images/bg-footer1.png);
    background-size: cover;
    background-position: top center;
    height: 20vh;
    display: flex;
    justify-content: center;
    margin-top: -84px;
    padding-bottom: 10px;
  }
  @media (max-width: 1920px) and (height: 1080px) {
    .page {
      background-position: center center;
    }
  }
  @media only screen and (width: 1280px) and (height: 950px) {
    .page {
      background-position: center;
    }
    footer {
      margin-top: -84px;
    }
  }
  @media (min-width: 1440px) and (height: 900px) {
    .page {
      background-position: center;
    }
    footer {
      margin-top: -86px;
    }
  }
  @media (width: 1366px) and (max-height: 768px) {
    .page {
      background-position: center;
    }
    footer {
      margin-top: -93px;
    }
    .circle {
      background-image: url(../images/circle.png);
      width: 90px;
      height: 90px;
      font-size: 8px;
      &.pst1 {
        top: 10px;
        right: 150px;
      }
      &.pst2 {
        top: 75px;
        right: 65px;
      }

      &.pst3 {
        top: 175px;
        right: 35px;
      }

      &.pst4 {
        top: 290px;
        right: 30px;
      }
      &.pst5 {
        top: 385px;
        right: 110px;
      }
      &.pst6 {
        top: 445px;
        right: 210px;
      }
    }
    .content-nang-luc {
      max-height: 65vh;
      padding: 0;
    }
    .page {
      background-image: url(/Themes/ThienMinh/Content/images/bg-nang-luc1.png);
    }
  }
  @media only screen and (width: 1280px) and (height: 800px) {
    .page {
      background-position: center;
    }
    footer {
      margin-top: -91px;
    }
    .circle {
      background-image: url(../images/circle.png);
      width: 90px;
      height: 90px;
      font-size: 8px;
      &.pst1 {
        top: 10px;
        right: 150px;
      }
      &.pst2 {
        top: 75px;
        right: 65px;
      }

      &.pst3 {
        top: 175px;
        right: 35px;
      }

      &.pst4 {
        top: 290px;
        right: 30px;
      }
      &.pst5 {
        top: 445px;
        right: 110px;
      }
      &.pst6 {
        top: 510px;
        right: 225px;
      }
    }
    .content-nang-luc {
      max-height: 65vh;
      padding: 0;
    }
  }
  @media (min-width: 2304px) and (height: 1440px) {
    .page {
      background-position: center center;
    }
    .content-nang-luc {
      max-height: 70vh;
      padding: 0;
    }
  }
  @media screen and (max-width: 1439px) and (min-width: 1281px) {
    .page {
      background-position: center center;
    }
    .content-nang-luc {
      max-height: 70vh;
      padding: 0;
    }
  }
  @media screen and (max-width: 1279px) and (min-width: 991px) {
    .page {
      background-position: center center;
    }
  }
  @media screen and (max-width: 1760px) and (min-width: 1441px) {
    .page {
      background-position: center center;
    }
    .content-nang-luc {
      max-height: 70vh;
      padding: 0;
    }
  }
  @media screen and (max-width: 1439px) and (min-width: 1281px) {
    .page {
      background-position: center center;
    }
  }
  @media screen and (max-width: 4380px) and (min-width: 2128px) {
    .page {
      background-position: 0 -110px;
    }
    footer {
      margin-top: -79px;
    }
  }

  @media screen and (max-height: 767px) {
    .group {
      min-height: auto;
    }

    footer {
      margin-top: -57px;
      padding-bottom: 3px;
    }
    .circle {
      width: 78px;
      height: 78px;
      &.pst1 {
        top: 10px;
        right: 150px;
      }
      &.pst2 {
        top: 75px;
        right: 65px;
      }

      &.pst3 {
        top: 175px;
        right: 35px;
      }

      &.pst4 {
        top: 290px;
        right: 30px;
      }
      &.pst5 {
        top: 385px;
        right: 110px;
      }
      &.pst6 {
        top: 445px;
        right: 210px;
      }
    }
    .btn-nangluc.active .circle {
      width: 75px;
      height: 75px;
    }
  }

  /* Portrait */
  @media only screen and (min-width: 768px) and (max-height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .page {
      background-image: none;
    }
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    .page {
      background-image: none;
    }
  }
  @media (min-width: 992px) and (height: 600px) {
    .page {
      background-image: url(/Themes/ThienMinh/Content/images/bg-nang-luc1.png);
    }
  }
  /* ----------- iPhone 6+, 7+ and 8+ ----------- */
  /* Portrait */
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    .page {
      background-image: none;
    }
  }
  /*iPhone 6, 7, & 8 in portrait*/
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
    .page {
      background-image: none;
    }
  }
}
